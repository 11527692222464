import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect
} from "../_snowpack/pkg/react.js";
import * as Sentry from "../_snowpack/pkg/@sentry/browser.js";
import tiny from "../_snowpack/pkg/tinycolor2.js";
import {useSnackbar} from "../_snowpack/pkg/react-simple-snackbar.js";
import {Plugins} from "../_snowpack/pkg/@capacitor/core.js";
import {debounce} from "../_snowpack/pkg/throttle-debounce.js";
import firebase from "../_snowpack/pkg/firebase/app.js";
import * as uuid from "../_snowpack/pkg/uuid.js";
import {createEmitter} from "./events.js";
const {Storage} = Plugins;
export const addEventListener = (type, ev, options) => {
  window.addEventListener(type, ev, options);
  return {
    dispose: () => {
      window.removeEventListener(type, ev);
    }
  };
};
export const addEventListeners = (events, options) => {
  const types = Object.keys(events);
  const remove = () => {
    for (const type of types) {
      const ev = events[type];
      if (ev === "remove") {
        continue;
      }
      window.removeEventListener(type, ev);
    }
  };
  for (const type of types) {
    const ev = events[type];
    if (ev === "remove") {
      events[type] = remove;
    }
    window.addEventListener(type, ev, options);
  }
  return {
    dispose: remove
  };
};
export function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}
export const Keys = {
  Backspace: 8,
  Tab: 9,
  Return: 13,
  Shift: 16,
  Ctrl: 17,
  Esc: 27,
  Space: 32,
  Left: 37,
  Up: 38,
  Right: 39,
  Down: 40,
  Delete: 46,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  Cmd: 91
};
export const Mouse = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2
};
export const preventAndCall = (f) => (e) => {
  e.preventDefault();
  return f(e);
};
export const useDocumentTitle = (title, retainOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
  useEffect(() => {
    return () => {
      if (!retainOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [retainOnUnmount]);
};
export const wrap = (f) => () => {
  f();
};
export const captureAndLog = (e, extra) => {
  extra = extra || {};
  if (e.response) {
    extra.response = e.response;
  }
  if (e.config) {
    extra.config = Object.assign({}, e.config);
    delete extra.config.data;
  }
  Sentry.captureException(e, {extra});
  console.error(e);
};
export const captureAndLogError = (e, extra) => {
  Sentry.captureMessage(e, {
    level: Sentry.Severity.Error,
    extra
  });
  console.error(e);
};
export function fmtMSS(s) {
  s = Math.round(s);
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}
export const pluralSongs = (count) => count === 1 ? "song" : "songs";
export const songsCount = (count) => `${count ?? 0} ${pluralSongs(count)}`;
export const fmtToDate = (timestamp) => new Date(timestamp.toMillis()).toLocaleDateString("en", {
  month: "short",
  day: "numeric",
  year: "numeric"
});
export const useGradient = (color, amount = 5) => {
  const {to, from} = useMemo(() => ({
    from: tiny(color).lighten(amount),
    to: tiny(color).darken(amount)
  }), [amount, color]);
  const isLight = useMemo(() => tiny(color).isLight(), [color]);
  return {
    to,
    from,
    isLight
  };
};
const debouncedStorageSet = debounce(500, Storage.set.bind(Storage));
export function getLocalStorage(key, defaultValue) {
  const getValue = async () => await Storage.get({key}).then(({value}) => value ?? defaultValue);
  const setValue = (value) => Storage.set({key, value});
  return [getValue, setValue];
}
export function useLocalStorage(key, defaultValue) {
  const [value, setValue, ref] = useStateWithRef(defaultValue);
  useEffect(() => {
    Storage.get({key}).then(({value: value2}) => {
      if (value2 !== null) {
        setValue(value2);
      }
    });
  }, [key, setValue]);
  const setValueAndStore = useCallback((value2) => {
    setValue(value2);
    debouncedStorageSet({key, value: value2});
  }, [key, setValue]);
  return [value, setValueAndStore, ref];
}
export function useOnClickOutside(ref, handler, exclude) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target) || exclude && exclude.current && exclude.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, exclude]);
}
export const useCombinedRefs = (...refs) => {
  return useCallback((element) => refs.forEach((ref) => {
    if (!ref) {
      return;
    }
    if (typeof ref === "function") {
      return ref(element);
    }
    ref.current = element;
  }), refs);
};
export const shuffleArray = (array, first) => {
  let currentIndex = array.length;
  const shuffled = array.slice(0);
  const mappingFrom = {};
  const swap = (a, b) => {
    const temporaryValue = shuffled[a];
    shuffled[a] = shuffled[b];
    shuffled[b] = temporaryValue;
    const temporaryIndex = mappingFrom[a] ?? a;
    mappingFrom[a] = mappingFrom[b] ?? b;
    mappingFrom[b] = temporaryIndex;
  };
  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    swap(currentIndex, randomIndex);
  }
  const mappingTo = reverseMapping(mappingFrom);
  if (first !== void 0) {
    const x = mappingFrom[0];
    swap(0, mappingTo[first]);
    mappingTo[x] = mappingTo[first];
    mappingTo[first] = 0;
  }
  return {
    shuffled,
    mappingTo,
    mappingFrom
  };
};
export const numberKeys = (record) => {
  return Object.keys(record).map((key) => +key);
};
export const reverseMapping = (mapping) => {
  const reverse = {};
  numberKeys(mapping).map((key) => {
    reverse[mapping[key]] = key;
  });
  return reverse;
};
export const removeElementFromShuffled = (index, {mappingTo, mappingFrom, shuffled}) => {
  const original = mappingFrom[index];
  const newShuffled = [...shuffled.slice(0, index), ...shuffled.slice(index + 1)];
  const newMappingTo = {};
  for (let i = 0; i < shuffled.length; i++) {
    if (i === original)
      continue;
    const toIndex = i > original ? i - 1 : i;
    const fromIndex = mappingTo[i] > index ? mappingTo[i] - 1 : mappingTo[i];
    newMappingTo[toIndex] = fromIndex;
  }
  return {
    shuffled: newShuffled,
    mappingFrom: reverseMapping(newMappingTo),
    mappingTo: newMappingTo
  };
};
export const IS_WEB_VIEW = !!__SNOWPACK_ENV__?.SNOWPACK_PUBLIC_MOBILE;
let saved;
export const isMobile = () => {
  if (saved !== void 0)
    return saved;
  saved = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent) || IS_WEB_VIEW;
  return saved;
};
export const useIsMobile = () => useMemo(() => isMobile(), []);
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
let defaultErrorHandlers = [];
const onConditionsFunction = (f, onSuccess, onError, onSettled) => {
  const successCallback = [];
  const errorCallbacks = [];
  const settledCallbacks = [];
  onSuccess && successCallback.push(onSuccess);
  onError && errorCallbacks.push(onError);
  onSettled && settledCallbacks.push(onSettled);
  const promise = f().then((result) => {
    successCallback.forEach((cb) => cb(result));
    settledCallbacks.forEach((cb) => cb());
    return result;
  }).catch((e) => {
    defaultErrorHandlers.forEach((cb) => cb(e));
    errorCallbacks.forEach((cb) => cb(e));
    settledCallbacks.forEach((cb) => cb());
    return void 0;
  });
  const chains = {
    onError: (cb) => {
      errorCallbacks.push(cb);
      return promiseAndChains;
    },
    onSuccess: (cb) => {
      successCallback.push(cb);
      return promiseAndChains;
    },
    onSettled: (cb) => {
      settledCallbacks.push(cb);
      return promiseAndChains;
    }
  };
  const promiseAndChains = Object.assign(promise, chains);
  return promiseAndChains;
};
export const onConditions = Object.assign(onConditionsFunction, {
  registerDefaultErrorHandler: (cb) => {
    defaultErrorHandlers.push(cb);
    return () => {
      defaultErrorHandlers = defaultErrorHandlers.filter((handler) => handler !== cb);
    };
  }
});
function getOnlineStatus() {
  return typeof window.navigator !== "undefined" && typeof window.navigator.onLine === "boolean" ? window.navigator.onLine : true;
}
export function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
  const goOnline = () => setOnlineStatus(true);
  const goOffline = () => setOnlineStatus(false);
  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);
  return onlineStatus;
}
const emitter = createEmitter();
export const openSnackbar = (node, duration = 5e4) => {
  emitter.emit("open", node, duration);
};
export const useMySnackbar = () => {
  const mobile = useIsMobile();
  const [open] = useSnackbar(mobile ? {style: {zIndex: 50}} : {position: "top-right", style: {transform: "translateY(60px)", zIndex: 50}});
  useEffect(() => emitter.on("open", (node, duration) => open(node, duration)), []);
};
export const parseIntOr = (value, defaultValue) => {
  if (value === void 0)
    return defaultValue;
  const parsed = parseInt(value);
  return parsed ? parsed : defaultValue;
};
export function useStateWithRef(value) {
  const [state, setState] = useState(value);
  const ref = useRef(value);
  const setStateAndRef = useCallback((value2) => {
    setState(value2);
    ref.current = value2;
  }, []);
  return [state, setStateAndRef, ref];
}
export const isDefinedSnapshot = (snapshot) => snapshot.exists;
export const clientDb = (userId) => {
  const db = firebase.firestore();
  return {
    userId,
    song: (songId) => db.doc(`user_data/${userId}/songs/${songId}`),
    songs: () => db.collection(`user_data/${userId}/songs`),
    doc: () => db.doc(`user_data/${userId}`),
    playlists: () => db.collection(`user_data/${userId}/playlists`),
    playlist: (id) => db.doc(`user_data/${userId}/playlists/${id}`),
    feedback: (id) => db.doc(`user_data/${userId}/feedback/${id}`),
    actions: () => db.collection(`user_data/${userId}/actions`)
  };
};
export const clientStorage = (storage, userId) => {
  return {
    artworks: (hash, type) => {
      const artworksPath = `${userId}/song_artwork/${hash}`;
      return {
        original: () => storage.ref(`${artworksPath}/artwork.${type}`),
        "32": () => storage.ref(`${artworksPath}/thumb@32_artwork.${type}`),
        "64": () => storage.ref(`${artworksPath}/thumb@64_artwork.${type}`),
        "128": () => storage.ref(`${artworksPath}/thumb@128_artwork.${type}`),
        "256": () => storage.ref(`${artworksPath}/thumb@256_artwork.${type}`)
      };
    },
    song: (songId, fileName) => storage.ref(`${userId}/songs/${songId}/${fileName}`),
    feedbackUpload: (id, fileName) => storage.ref(`${userId}/feedback/${id}/${uuid.v4().slice(0, 8)}_${fileName}`)
  };
};
export const toFileArray = (fileList) => {
  if (!fileList)
    return [];
  const files = [];
  for (let i = 0; i < fileList.length; i++)
    files.push(fileList[i]);
  return files;
};
export const bytesToHumanReadable = (bytes) => {
  if (bytes == 0) {
    return "0B";
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + "" + " KMGTP".charAt(e) + "B";
};
export const closeMobileKeyboard = (element) => {
  element.readOnly = true;
  element.disabled = true;
  setTimeout(function() {
    element.blur();
    element.readOnly = false;
    element.disabled = false;
  }, 100);
};
export const useIsMounted = () => {
  const isMounted = useRef(true);
  useEffect(() => () => {
    isMounted.current = false;
  }, []);
  return isMounted;
};
export const itemsToFiles = () => {
};
export function assertUnreachable(x) {
  throw new Error("Didn't expect to get here: " + x);
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
