import React, {useState, useCallback} from "../../_snowpack/pkg/react.js";
import {Input} from "./Input.js";
import {signInWithEmailAndPassword, useUser} from "../auth.js";
import {captureAndLogError} from "../utils.js";
import {BlockAlert} from "./BlockAlert.js";
import {OkCancelModal} from "./OkCancelModal.js";
export const ConfirmPassword = ({onCancel, onConfirm}) => {
  const {user} = useUser();
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const tryAndConfirm = useCallback(async () => {
    if (!user) {
      setError("You are not logged in and cannot confirm your password.");
      return;
    }
    if (!user.email) {
      captureAndLogError("The users email was undefined when trying to confirm password. Something isn't right.");
      return;
    }
    const result = await signInWithEmailAndPassword(user.email, value);
    if (result.isOk()) {
      onConfirm();
    } else {
      setError(result.error.message);
    }
  }, [onConfirm, user, value]);
  return /* @__PURE__ */ React.createElement(OkCancelModal, {
    onCancel,
    onOk: tryAndConfirm,
    initialFocus: "#password-confirm-box",
    titleText: "Confirm Password",
    okText: "Confirm",
    wrapperClassName: "space-y-2 dark:text-gray-200"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", {
    className: "font-bold"
  }, "Confirm Your Password"), /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-gray-500 dark:text-gray-400"
  }, "Why are we asking you about your password? We sometimes need to confirm your identity when you are performing sensitive operations :)")), /* @__PURE__ */ React.createElement(Input, {
    label: "Password",
    type: "password",
    value,
    onChange: (value2) => setValue(value2),
    inputId: "password-confirm-box",
    onEnter: tryAndConfirm
  }), error && /* @__PURE__ */ React.createElement(BlockAlert, {
    type: "error"
  }, error));
};
