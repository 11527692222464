import React from "../../_snowpack/pkg/react.js";
import classNames from "../../_snowpack/pkg/classnames.js";
import {Audio} from "./Audio.js";
export const LoadingSpinner = (props) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: classNames("text-purple-600 dark:text-purple-500 flex flex-col items-center justify-center w-full space-y-2", props.className)
  }, /* @__PURE__ */ React.createElement(Audio, {
    className: "w-12 h-16"
  }), props.text && /* @__PURE__ */ React.createElement("div", null, props.text));
};
