import React, {useCallback, useContext, useEffect, useRef} from "../../_snowpack/pkg/react.js";
import {Plugins} from "../../_snowpack/pkg/@capacitor/core.js";
import {createContext} from "../../_snowpack/pkg/react.js";
import {IS_WEB_VIEW} from "../utils.js";
const {StatusBar} = Plugins;
const StatusBarContext = createContext({
  setDefault: () => {
  },
  setTemporary: () => () => {
  }
});
export const StatusBarProvider = ({children}) => {
  const style = useRef();
  const temporary = useRef();
  const setTemporary = useCallback((temp) => {
    if (!IS_WEB_VIEW)
      return () => {
      };
    temporary.current = temp;
    StatusBar.setStyle({style: temp});
    return () => {
      style.current && StatusBar.setStyle({style: style.current});
      temporary.current = void 0;
    };
  }, []);
  const setDefault = useCallback((def) => {
    if (!IS_WEB_VIEW)
      return;
    style.current = def;
    !temporary.current && StatusBar.setStyle({style: def});
  }, []);
  return /* @__PURE__ */ React.createElement(StatusBarContext.Provider, {
    value: {setTemporary, setDefault}
  }, children);
};
export const useStatusBarProvider = () => useContext(StatusBarContext);
export const useTemporaryStatusBar = ({style, use}) => {
  const {setTemporary} = useStatusBarProvider();
  useEffect(() => {
    if (use) {
      return setTemporary(style);
    }
  }, [setTemporary, style, use]);
};
export const useDefaultStatusBar = (style) => {
  const {setDefault} = useStatusBarProvider();
  useEffect(() => {
    setDefault(style);
  }, [setDefault, style]);
};
