import * as Sentry from "../_snowpack/pkg/@sentry/browser.js";
import {useEffect, useMemo, useState} from "../_snowpack/pkg/react.js";
import {createEmitter} from "./events.js";
const navigator = window.navigator;
let registrationForUpdate;
let deferredPrompt;
const emitter = createEmitter();
emitter.on("setServiceWorker", (registration) => {
  registrationForUpdate = registration;
});
emitter.on("setDeferredPrompt", (event) => {
  deferredPrompt = event;
});
export const useDeferredInstallPrompt = () => {
  const [event, setEvent] = useState(deferredPrompt);
  useEffect(() => emitter.on("setDeferredPrompt", setEvent), []);
  return useMemo(() => {
    if (!event)
      return;
    return () => event.prompt();
  }, [event]);
};
export const useUpdatableServiceWorker = () => {
  const [registration, setRegistration] = useState(registrationForUpdate);
  useEffect(() => emitter.on("setServiceWorker", (registration2) => setRegistration(registration2)));
  return useMemo(() => {
    if (!registration)
      return;
    return () => {
      if (registration.waiting) {
        registration.waiting.postMessage("SKIP_WAITING");
      }
    };
  }, [registration]);
};
export const registerWorker = () => {
  if (!("serviceWorker" in navigator))
    return;
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    emitter.emit("setDeferredPrompt", e);
  });
  window.addEventListener("load", async () => {
    let registration;
    try {
      registration = await navigator.serviceWorker.register("/sw.js");
      console.info("Service worker initialized successfully \u2728");
    } catch (e) {
      console.error("Service worker failed to initialize \u26A0");
      console.error(e);
      Sentry.captureException(e);
      return;
    }
    if (registration.waiting) {
      emitter.emit("setServiceWorker", registration);
    }
    registration.addEventListener("updatefound", () => {
      if (registration.installing) {
        registration.installing.addEventListener("statechange", () => {
          if (registration.waiting) {
            if (navigator.serviceWorker.controller) {
              emitter.emit("setServiceWorker", registration);
            } else {
              console.info("Service Worker initialized for the first time \u{1F64C}");
            }
          }
        });
      }
    });
    let refreshing = false;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (!refreshing) {
        window.location.reload();
        refreshing = true;
      }
    });
  });
};
export const unregisterWorker = () => {
  if (!("serviceWorker" in navigator))
    return;
  navigator.serviceWorker.ready.then((registration) => registration.unregister());
};
