import React from "../../_snowpack/pkg/react.js";
import classNames from "../../_snowpack/pkg/classnames.js";
import {Keys, parseIntOr} from "../utils.js";
import {field} from "../classes.js";
export const Input = (props) => {
  return /* @__PURE__ */ React.createElement("label", {
    className: classNames("block relative space-y-1", props.labelClassName)
  }, props.label && /* @__PURE__ */ React.createElement("span", {
    className: classNames(props.spanClassName, "mb-1 dark:text-gray-200")
  }, props.label), /* @__PURE__ */ React.createElement("input", {
    ref: props.inputRef,
    required: props.required,
    value: props.value ?? "",
    type: props.type,
    id: props.inputId,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    className: classNames("w-full", field(), props.inputClassName, props.iconRight && "pr-10"),
    placeholder: props.placeholder,
    onChange: (e) => props.type === "number" ? props.onChange(parseIntOr(e.target.value, void 0)) : props.onChange(e.target.value),
    onKeyDown: (e) => e.keyCode === Keys.Return && props.onEnter && props.onEnter(),
    autoFocus: props.autoFocus
  }), props.iconRight && /* @__PURE__ */ React.createElement("div", {
    className: classNames("flex absolute right-0 inset-y-0 items-center mr-3", props.iconClassName)
  }, /* @__PURE__ */ React.createElement(props.iconRight, {
    className: "h-6 w-6"
  })));
};
