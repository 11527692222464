import React from "../../_snowpack/pkg/react.js";
export const Audio = ({
  disabled,
  ...props
}) => /* @__PURE__ */ React.createElement("svg", {
  fill: "currentColor",
  viewBox: "0 0 55 80",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  transform: "matrix(1 0 0 -1 0 80)"
}, /* @__PURE__ */ React.createElement("rect", {
  width: 10,
  height: disabled ? 20 : 20,
  rx: 3
}, !disabled && /* @__PURE__ */ React.createElement("animate", {
  attributeName: "height",
  begin: "0s",
  dur: "4.3s",
  values: "20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20",
  calcMode: "linear",
  repeatCount: "indefinite"
})), /* @__PURE__ */ React.createElement("rect", {
  x: 15,
  width: 10,
  height: disabled ? 20 : 80,
  rx: 3
}, !disabled && /* @__PURE__ */ React.createElement("animate", {
  attributeName: "height",
  begin: "0s",
  dur: "2s",
  values: "80;55;33;5;75;23;73;33;12;14;60;80",
  calcMode: "linear",
  repeatCount: "indefinite"
})), /* @__PURE__ */ React.createElement("rect", {
  x: 30,
  width: 10,
  height: disabled ? 20 : 50,
  rx: 3
}, !disabled && /* @__PURE__ */ React.createElement("animate", {
  attributeName: "height",
  begin: "0s",
  dur: "1.4s",
  values: "50;34;78;23;56;23;34;76;80;54;21;50",
  calcMode: "linear",
  repeatCount: "indefinite"
})), /* @__PURE__ */ React.createElement("rect", {
  x: 45,
  width: 10,
  height: disabled ? 20 : 30,
  rx: 3
}, !disabled && /* @__PURE__ */ React.createElement("animate", {
  attributeName: "height",
  begin: "0s",
  dur: "2s",
  values: "30;45;13;80;56;72;45;76;34;23;67;30",
  calcMode: "linear",
  repeatCount: "indefinite"
}))));
