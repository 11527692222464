
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import {env} from "./env.js";
import * as Sentry from "../_snowpack/pkg/@sentry/browser.js";
import {isMobile, IS_WEB_VIEW} from "./utils.js";
if (__SNOWPACK_ENV__ && __SNOWPACK_ENV__?.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    release: (IS_WEB_VIEW ? "mobile" : "app") + "@" + env.version,
    dsn: env.sentryDsn,
    beforeSend: (e) => {
      if (e.message === "AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22") {
        return null;
      }
      return e;
    }
  });
}
import * as React from "../_snowpack/pkg/react.js";
import * as ReactDOM from "../_snowpack/pkg/react-dom.js";
import {Router} from "../_snowpack/pkg/little-react-router.js";
import {routes} from "./routes.js";
import {UserProvider} from "./auth.js";
import {ConfirmActionProvider} from "./confirm-actions.js";
import {ConfirmPasswordProvider} from "./confirm-password.js";
import {ModalProvider} from "../_snowpack/pkg/react-modal-hook.js";
import "./firebase.js";
import "./tailwind.css";
import "./common.css";
import SnackbarProvider from "../_snowpack/pkg/react-simple-snackbar.js";
import {DarkModeProvider, useDarkMode} from "./dark.js";
import {registerWorker} from "./service-worker.js";
import {SkeletonTheme} from "../_snowpack/pkg/react-loading-skeleton.js";
import {StatusBarProvider} from "./mobile/status-bar.js";
import {LoadingPage} from "./components/LoadingPage.js";
const App = React.lazy(() => isMobile() ? import("./mobile/App.js") : import("./web/App.js"));
const SkeletonProvider = ({children}) => {
  const [darkMode] = useDarkMode();
  const {color, highlightColor} = React.useMemo(() => ({
    color: darkMode ? "rgba(255, 255, 255, 0.05)" : void 0,
    highlightColor: darkMode ? "rgba(255, 255, 255, 0.00)" : void 0
  }), [darkMode]);
  return /* @__PURE__ */ React.createElement(SkeletonTheme, {
    color,
    highlightColor
  }, children);
};
ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(DarkModeProvider, null, /* @__PURE__ */ React.createElement(UserProvider, null, /* @__PURE__ */ React.createElement(SnackbarProvider, null, /* @__PURE__ */ React.createElement(ModalProvider, null, /* @__PURE__ */ React.createElement(Router, {
  routes
}, /* @__PURE__ */ React.createElement(ConfirmActionProvider, null, /* @__PURE__ */ React.createElement(ConfirmPasswordProvider, null, /* @__PURE__ */ React.createElement(SkeletonProvider, null, /* @__PURE__ */ React.createElement(StatusBarProvider, null, /* @__PURE__ */ React.createElement(React.Suspense, {
  fallback: /* @__PURE__ */ React.createElement(LoadingPage, {
    className: "h-screen"
  })
}, /* @__PURE__ */ React.createElement(App, null)))))))))))), document.getElementById("root"));
if (undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
  undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
}
if (!IS_WEB_VIEW)
  registerWorker();
