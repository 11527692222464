import React, {createContext, useContext, useRef} from "../_snowpack/pkg/react.js";
import {ConfirmPassword} from "./components/ConfirmPassword.js";
import {useModal} from "../_snowpack/pkg/react-modal-hook.js";
export const ConfirmPasswordContext = createContext(null);
export const ConfirmPasswordProvider = (props) => {
  const [show, close] = useModal(() => /* @__PURE__ */ React.createElement(ConfirmPassword, {
    onCancel: () => setDisplayAndCall(false),
    onConfirm: () => setDisplayAndCall(true)
  }));
  const cb = useRef();
  const confirmPassword = () => {
    return new Promise((resolve) => {
      show();
      cb.current = resolve;
    });
  };
  const setDisplayAndCall = (confirmed) => {
    close();
    if (cb.current) {
      cb.current(confirmed);
      cb.current = void 0;
    }
  };
  return /* @__PURE__ */ React.createElement(ConfirmPasswordContext.Provider, {
    value: {confirmPassword}
  }, props.children);
};
export const useConfirmPassword = () => {
  return useContext(ConfirmPasswordContext);
};
