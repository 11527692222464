import {useCoolSongs} from "../db.js";
import {useMemo} from "../../_snowpack/pkg/react.js";
export const ALBUM_ID_DIVIDER = "<<<<<<<";
export const getAlbumArtistFromSong = (song) => (song.albumArtist || song.artist) ?? "";
export const useAlbumLookup = () => {
  const songs = useCoolSongs();
  return useMemo(() => {
    const lookup = {};
    songs?.forEach((song) => {
      const artist = getAlbumArtistFromSong(song);
      const album = song.albumName ?? "";
      if (!lookup[artist])
        lookup[artist] = {};
      if (!lookup[artist][album])
        lookup[artist][album] = {
          id: `${artist}${ALBUM_ID_DIVIDER}${album}`,
          album,
          artist,
          songs: []
        };
      lookup[artist][album].songs.push(song);
    });
    Object.values(lookup).forEach((subLookup) => Object.values(subLookup).forEach((album) => {
      album.songs = album.songs.sort((a, b) => {
        const noA = a.track?.no ?? null;
        const noB = b.track?.no ?? null;
        if (noA === null && noB === null)
          return a.title.localeCompare(b.title);
        else if (noA !== null && noB !== null)
          return noA - noB;
        else if (noA !== null)
          return -1;
        else
          return 1;
      });
    }));
    return lookup;
  }, [songs]);
};
export const useAlbums = () => {
  const lookup = useAlbumLookup();
  return useMemo(() => {
    const lookups = Object.values(lookup);
    const arrays = lookups.map((lookup2) => Object.values(lookup2));
    const albums = Array.prototype.concat.apply([], arrays);
    return albums.sort((a, b) => {
      if (a.album === "")
        return 1;
      if (b.album === "")
        return -1;
      const result = a.album.localeCompare(b.album);
      return result === 0 ? b.artist.localeCompare(b.artist) : result;
    });
  }, [lookup]);
};
export const useAlbum = ({
  album,
  artist
}) => {
  const albums = useAlbumLookup();
  return useMemo(() => albums[artist] && albums[artist][album], [albums, artist, album]);
};
