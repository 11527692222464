import React from "../../_snowpack/pkg/react.js";
import AriaModal from "../../_snowpack/pkg/react-aria-modal.js";
import {LoadingSpinner} from "./LoadingSpinner.js";
import classNames from "../../_snowpack/pkg/classnames.js";
import {HiOutlineX} from "../../_snowpack/pkg/react-icons/hi.js";
export const Modal = ({
  children,
  onExit,
  initialFocus = "#modal-close",
  titleText,
  loading,
  className,
  style
}) => {
  return /* @__PURE__ */ React.createElement(AriaModal, {
    titleText,
    onExit,
    initialFocus,
    getApplicationNode: () => document.getElementById("root"),
    underlayStyle: {paddingTop: "2em"}
  }, /* @__PURE__ */ React.createElement("div", {
    className: classNames("bg-white dark:bg-gray-900 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-xl sm:w-full"),
    role: "dialog",
    "aria-modal": "true",
    "aria-labelledby": "modal-headline",
    onClick: (e) => e.stopPropagation()
  }, loading && /* @__PURE__ */ React.createElement("div", {
    className: "absolute bg-white dark:bg-gray-800 opacity-75 inset-0 z-10"
  }), loading && /* @__PURE__ */ React.createElement(LoadingSpinner, {
    className: "absolute inset-0 z-10"
  }), /* @__PURE__ */ React.createElement("button", {
    id: "modal-close",
    className: "absolute right-0 top-0 my-3 mx-4 dark:text-gray-200",
    onClick: onExit
  }, /* @__PURE__ */ React.createElement(HiOutlineX, {
    className: "w-4 h-4"
  })), /* @__PURE__ */ React.createElement("div", {
    className,
    style
  }, children)));
};
