import React, {createContext, useContext, useRef, useCallback} from "../_snowpack/pkg/react.js";
import {ActionConfirmationModal} from "./components/ActionConfirmationModal.js";
import {useModal} from "../_snowpack/pkg/react-modal-hook.js";
import {isMobile} from "./utils.js";
import {Modals} from "../_snowpack/pkg/@capacitor/core.js";
export const ConfirmActionContext = createContext({});
export const ConfirmActionProvider = (props) => {
  const cb = useRef();
  const [show, close] = useModal(() => /* @__PURE__ */ React.createElement(ActionConfirmationModal, {
    title: cb.current?.props?.title ?? "",
    subtitle: cb.current?.props?.subtitle ?? "",
    confirmText: cb.current?.props?.confirmText ?? "",
    confirmEmail: cb.current?.props?.confirmEmail,
    onCancel: () => {
      cb.current?.props.onCancel && cb.current.props.onCancel();
      setDisplayAndCall(false);
    },
    onConfirm: async () => {
      cb.current?.props.onConfirm && await cb.current?.props.onConfirm();
      setDisplayAndCall(true);
    }
  }));
  const confirmAction = useCallback((props2) => {
    return new Promise((resolve) => {
      if (isMobile()) {
        Modals.confirm({
          title: props2.title,
          message: props2.subtitle,
          okButtonTitle: props2.confirmText
        }).then(async ({value}) => {
          if (value && props2.onConfirm)
            await props2.onConfirm();
          resolve(value);
        });
      } else {
        cb.current = {resolve, props: props2};
        show();
      }
    });
  }, [show]);
  const setDisplayAndCall = (confirmed) => {
    close();
    if (cb.current) {
      cb.current.resolve(confirmed);
      cb.current = void 0;
    }
  };
  return /* @__PURE__ */ React.createElement(ConfirmActionContext.Provider, {
    value: {confirmAction}
  }, props.children);
};
export const useConfirmAction = () => {
  return useContext(ConfirmActionContext);
};
