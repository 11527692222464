import React, {useCallback, useContext, useEffect, useRef} from "../_snowpack/pkg/react.js";
import {createContext} from "../_snowpack/pkg/react.js";
import {useLocalStorage} from "./utils.js";
const DarkModeContext = createContext([false, () => {
}, {current: false}]);
export const DarkModeProvider = ({children}) => {
  const [darkMode, setDarkMode] = useLocalStorage("dark-mode-enabled", window.matchMedia("(prefers-color-scheme: dark)").matches ? "true" : "false");
  const ref = useRef(darkMode === "true");
  const setDarkModeWithBool = useCallback((value) => {
    setDarkMode(value ? "true" : "false");
    ref.current = value;
  }, [setDarkMode]);
  useEffect(() => {
    if (darkMode === "false") {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
  }, [darkMode]);
  return /* @__PURE__ */ React.createElement(DarkModeContext.Provider, {
    value: [darkMode === "true", setDarkModeWithBool, ref]
  }, children);
};
export const useDarkMode = () => {
  return useContext(DarkModeContext);
};
