import firebase from "../_snowpack/pkg/firebase/app.js";
import "../_snowpack/pkg/firebase/auth.js";
import "../_snowpack/pkg/firebase/storage.js";
import "../_snowpack/pkg/firebase/firestore.js";
import "../_snowpack/pkg/firebase/analytics.js";
import "../_snowpack/pkg/firebase/performance.js";
import {env} from "./env.js";
firebase.initializeApp({
  apiKey: env.apiKey,
  authDomain: env.authDomain,
  projectId: env.projectId,
  storageBucket: env.storageBucket,
  messagingSenderId: env.messagingSenderId,
  appId: env.appId,
  measurementId: env.measurementId
});
