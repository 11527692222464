import * as React from "../../_snowpack/pkg/react.js";
import {useState} from "../../_snowpack/pkg/react.js";
import {useIsMounted} from "../utils.js";
import {button} from "../classes.js";
export const Button = ({
  invert,
  theme = "purple",
  height = "h-10",
  onClick,
  buttonRef,
  className,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  return /* @__PURE__ */ React.createElement("button", {
    ...props,
    ref: buttonRef,
    className: button({className, theme, invert}),
    onClick: async (e) => {
      if (!onClick)
        return;
      try {
        setLoading(true);
        await onClick(e);
      } finally {
        isMounted.current && setLoading(false);
      }
    },
    disabled: theme === "disabled" || loading
  }, loading && /* @__PURE__ */ React.createElement("svg", {
    className: "animate-spin -ml-1 mr-3 h-5 w-5",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24"
  }, /* @__PURE__ */ React.createElement("circle", {
    className: "opacity-25",
    cx: "12",
    cy: "12",
    r: "10",
    stroke: "currentColor",
    strokeWidth: "4"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "opacity-75",
    fill: "currentColor",
    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
  })), props.label);
};
