import React, {useEffect} from "../_snowpack/pkg/react.js";
import {useRouter} from "../_snowpack/pkg/little-react-router.js";
import {isMobile, IS_WEB_VIEW} from "./utils.js";
import {createEmitter} from "./events.js";
import {getAlbumArtistFromSong} from "./queries/album.js";
const Feedback = React.lazy(() => import("./pages/Feedback.js"));
const ReleaseNotes = React.lazy(() => import("./pages/ReleaseNotes.js"));
const BetaGuide = React.lazy(() => import("./pages/BetaGuide.js"));
const Login = React.lazy(() => import("./pages/Login.js"));
const Settings = React.lazy(() => isMobile() ? import("./mobile/pages/Settings.js") : import("./web/pages/Settings.js"));
const Search = React.lazy(() => import("./pages/Search.js"));
const SearchMobile = React.lazy(() => import("./mobile/pages/Search.js"));
const Signup = React.lazy(() => import("./pages/Signup.js"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword.js"));
const Home = React.lazy(() => import("./pages/Home.js"));
const Songs = React.lazy(() => import("./pages/Songs.js"));
const Artists = React.lazy(() => import("./pages/Artists.js"));
const Albums = React.lazy(() => import("./pages/Albums.js"));
const Playlists = React.lazy(() => import("./pages/Playlists.js"));
const Genres = React.lazy(() => import("./pages/Genres.js"));
const GenreOverview = React.lazy(() => import("./pages/GenreOverview.js"));
const AlbumOverview = React.lazy(() => import("./pages/AlbumOverview.js"));
const ArtistOverview = React.lazy(() => import("./pages/ArtistOverview.js"));
const Generated = React.lazy(() => import("./pages/Generated.js"));
const PlaylistOverview = React.lazy(() => import("./pages/PlaylistOverview.js"));
const ForgotPasswordSuccess = React.lazy(() => import("./pages/ForgotPasswordSuccess.js"));
const Hero = React.lazy(() => IS_WEB_VIEW ? import("./mobile/pages/Hero.js") : import("./web/pages/Hero.js"));
const Library = React.lazy(() => import("./mobile/pages/Library.js"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy.js"));
const TermsAndConditions = React.lazy(() => import("./pages/TermsAndConditions.js"));
const Invite = React.lazy(() => import("./pages/Invite.js"));
const createRoutes = (routes2) => routes2;
const emitter = createEmitter();
export const navigateTo = (route, params, queryParams) => {
  emitter.emit("navigate", routes[route], params, queryParams);
};
export const useNavigator = (_route) => {
  return useRouter();
};
export const useNavigation = () => {
  const {goTo} = useRouter();
  useEffect(() => {
    return emitter.on("navigate", (route, params, queryParams) => {
      goTo(route, params, queryParams);
    });
  }, []);
};
export const routes = createRoutes({
  invite: {
    id: "invite",
    path: "/invite/:invite",
    component: Invite,
    protected: false,
    sidebar: false,
    title: "Invite",
    showBack: false,
    showTabs: false
  },
  hero: {
    id: "hero",
    path: "/",
    component: Hero,
    protected: false,
    sidebar: false,
    className: "py-2",
    mobileClassName: "bg-gray-900",
    title: "",
    showBack: false,
    showTabs: false,
    dark: true
  },
  login: {
    id: "login",
    path: "/login",
    component: Login,
    protected: false,
    sidebar: false,
    className: "py-2",
    title: "Login",
    showBack: true,
    showTabs: false
  },
  signup: {
    id: "signup",
    path: "/signup",
    component: Signup,
    protected: false,
    sidebar: false,
    className: "py-2",
    title: "Sign Up",
    showBack: true,
    showTabs: false
  },
  forgotPassword: {
    id: "forgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    protected: false,
    sidebar: false,
    className: "py-2",
    title: "Forgot Password",
    showBack: true,
    showTabs: false
  },
  forgotPasswordSuccess: {
    id: "forgotPasswordSuccess",
    path: "/forgot-password-success",
    component: ForgotPasswordSuccess,
    protected: false,
    sidebar: false,
    className: "py-2",
    title: "Forgot Password Confirmation",
    showBack: false,
    showTabs: false
  },
  home: {
    id: "home",
    path: "/home",
    component: Home,
    protected: true,
    sidebar: true,
    className: "overflow-y-auto",
    title: "Home",
    showBack: false,
    showTabs: true
  },
  searchMobile: {
    id: "searchMobile",
    path: "/search/mobile",
    component: SearchMobile,
    protected: true,
    sidebar: true,
    className: "py-2",
    title: false,
    showBack: false,
    showTabs: true
  },
  search: {
    id: "search",
    path: "/search",
    component: Search,
    protected: true,
    sidebar: true,
    className: "py-2",
    title: "Search",
    showBack: true,
    showTabs: true
  },
  songs: {
    id: "songs",
    path: "/library/songs",
    component: Songs,
    protected: true,
    sidebar: true,
    title: "Songs",
    showBack: true,
    showTabs: true
  },
  "release-notes": {
    id: "release-notes",
    path: "/release-notes",
    component: ReleaseNotes,
    protected: false,
    sidebar: false,
    title: "Release Notes",
    showBack: true,
    showTabs: false
  },
  albums: {
    id: "albums",
    path: "/library/albums",
    component: Albums,
    protected: true,
    sidebar: true,
    title: "Albums",
    showBack: true,
    showTabs: true
  },
  album: {
    id: "album",
    path: "/library/albums/:artist@/:album@",
    component: AlbumOverview,
    protected: true,
    sidebar: true,
    title: "Album",
    showBack: true,
    showTabs: true
  },
  artist: {
    id: "artist",
    path: "/library/artists/:artistName",
    component: ArtistOverview,
    protected: true,
    sidebar: true,
    title: "Artist",
    showBack: true,
    showTabs: true
  },
  artists: {
    id: "artists",
    path: "/library/artists",
    component: Artists,
    protected: true,
    sidebar: true,
    title: "Artists",
    showBack: true,
    showTabs: true
  },
  playlists: {
    id: "playlists",
    path: "/library/playlists",
    component: Playlists,
    protected: true,
    sidebar: true,
    title: "Playlists",
    showBack: true,
    showTabs: true
  },
  playlist: {
    id: "playlist",
    path: "/library/playlists/:playlistId",
    component: PlaylistOverview,
    protected: true,
    sidebar: true,
    title: "Playlist",
    showBack: true,
    showTabs: true
  },
  "beta-guide": {
    id: "beta-guide",
    path: "/beta-guide",
    component: BetaGuide,
    protected: false,
    sidebar: false,
    title: "Beta Guide",
    showBack: true,
    showTabs: false
  },
  generated: {
    id: "generated",
    path: "/library/generated/:generatedType",
    component: Generated,
    protected: true,
    sidebar: true,
    title: "Generated",
    showBack: true,
    showTabs: true
  },
  privacy: {
    id: "privacy",
    path: "/privacy",
    component: PrivacyPolicy,
    protected: false,
    sidebar: false,
    title: "Privacy Policy",
    showBack: false,
    showTabs: false
  },
  terms: {
    id: "terms",
    path: "/terms",
    component: TermsAndConditions,
    protected: false,
    sidebar: false,
    title: "Terms and Conditions",
    showBack: false,
    showTabs: false
  },
  settings: {
    id: "settings",
    path: "/settings",
    component: Settings,
    protected: true,
    sidebar: false,
    title: "Settings",
    showBack: true,
    showTabs: false
  },
  library: {
    id: "library",
    path: "/library",
    component: Library,
    showBack: false,
    title: "Library",
    showTabs: true,
    protected: true,
    sidebar: false
  },
  genres: {
    id: "genres",
    path: "/library/genres",
    component: Genres,
    protected: true,
    sidebar: true,
    title: "Genres",
    showBack: true,
    showTabs: true
  },
  genre: {
    id: "genre",
    path: "/library/genres/:genre",
    component: GenreOverview,
    protected: true,
    sidebar: true,
    title: "Genre",
    showBack: true,
    showTabs: true
  },
  feedback: {
    id: "feedback",
    path: "/feedback",
    component: Feedback,
    protected: true,
    sidebar: true,
    title: "Feedback",
    showBack: true,
    showTabs: true
  }
});
export const getAlbumRouteParams = (song) => {
  return getAlbumParams({
    album: song.albumName ?? "",
    artist: getAlbumArtistFromSong(song)
  });
};
export const getAlbumParams = ({artist, album}) => {
  return {
    album: encodeURIComponent(album),
    artist: encodeURIComponent(artist)
  };
};
export const getArtistRouteParams = (artistName) => {
  return {artistName: encodeURIComponent(artistName)};
};
export const useAlbumParams = () => {
  const {params} = useNavigator("album");
  return {
    artist: decodeURIComponent(params.artist ?? ""),
    album: decodeURIComponent(params.album ?? "")
  };
};
export const useArtistNameFromParams = () => {
  const {params} = useRouter();
  const {artistName} = params;
  return decodeURIComponent(artistName);
};
