import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

const get = (key) => {
  return __SNOWPACK_ENV__ ? __SNOWPACK_ENV__[key] : void 0;
};
const getOrError = (key) => {
  const value = get(key);
  if (value === void 0)
    throw Error(`"${key}" is not defined`);
  return value;
};
export const env = {
  backendUrl: getOrError("SNOWPACK_PUBLIC_BACKEND_URL"),
  apiKey: getOrError("SNOWPACK_PUBLIC_API_KEY"),
  authDomain: getOrError("SNOWPACK_PUBLIC_AUTH_DOMAIN"),
  projectId: getOrError("SNOWPACK_PUBLIC_PROJECT_ID"),
  storageBucket: getOrError("SNOWPACK_PUBLIC_STORAGE_BUCKET"),
  messagingSenderId: getOrError("SNOWPACK_PUBLIC_MESSAGING_SENDER_ID"),
  appId: getOrError("SNOWPACK_PUBLIC_APP_ID"),
  measurementId: getOrError("SNOWPACK_PUBLIC_MEASUREMENT_ID"),
  version: getOrError("SNOWPACK_PUBLIC_PACKAGE_VERSION"),
  sentryDsn: getOrError("SNOWPACK_PUBLIC_SENTRY_DSN")
};
