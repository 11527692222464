import React, {useState} from "../../_snowpack/pkg/react.js";
import classNames from "../../_snowpack/pkg/classnames.js";
import {Button} from "./Button.js";
import {Modal} from "./Modal.js";
export const OkCancelModal = ({
  onCancel,
  children,
  onOk,
  okText = "Ok",
  initialFocus = "#modal-cancel-button",
  titleText,
  wrapperClassName,
  okTheme,
  wrapperStyle
}) => {
  const [loading, setLoading] = useState(false);
  return /* @__PURE__ */ React.createElement(Modal, {
    titleText,
    onExit: onCancel,
    initialFocus,
    loading
  }, /* @__PURE__ */ React.createElement("div", {
    className: classNames("px-5 pt-10 pb-5", wrapperClassName),
    style: wrapperStyle
  }, children), /* @__PURE__ */ React.createElement("div", {
    className: "bg-gray-50 dark:bg-transparent px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
  }, /* @__PURE__ */ React.createElement(Button, {
    id: "modal-confirm-button",
    theme: okTheme,
    label: okText,
    onClick: () => {
      (async () => {
        setLoading(true);
        try {
          if (onOk) {
            await onOk();
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  })), /* @__PURE__ */ React.createElement("span", {
    className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
  }, /* @__PURE__ */ React.createElement(Button, {
    id: "modal-cancel-button",
    label: "Cancel",
    theme: "none",
    onClick: onCancel
  }))));
};
