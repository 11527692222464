import mitt from "../_snowpack/pkg/mitt.js";
export const createEmitter = () => {
  const emitter = mitt();
  return {
    emitter,
    on: (key, listener) => {
      const wrapper = (args) => {
        listener(...args);
      };
      emitter.on(key, wrapper);
      return () => emitter.off(key, wrapper);
    },
    emit: (key, ...args) => {
      emitter.emit(key, args);
    }
  };
};
