import {openDB, deleteDB} from "../_snowpack/pkg/idb.js";
import {useEffect, useMemo} from "../_snowpack/pkg/react.js";
import {useUser} from "./auth.js";
import firebase from "../_snowpack/pkg/firebase/app.js";
import {createEmitter} from "./events.js";
import {captureMessage, Severity} from "../_snowpack/pkg/@sentry/browser.js";
import {useStateWithRef, clientDb} from "./utils.js";
const withPerformanceAndAnalytics = async (cb, name) => {
  const eventName = `${name}_initial_load`;
  const trace = firebase.performance().trace(eventName);
  trace.start();
  const result = await cb();
  trace.stop();
  trace.putMetric("count", result.length);
  firebase.analytics().logEvent(eventName, {
    value: result.length
  });
  return result;
};
let cache = {};
const watchers = createEmitter();
const emitter = createEmitter();
export const resetDB = (db) => {
  emitter.emit("close");
  setTimeout(() => {
    deleteDB(db, {
      blocked: () => {
        console.warn("Deleting the database was blocked. You may have to restart your browser.");
      }
    }).then(() => {
      window.location.reload();
    });
  }, 4e3);
};
export class IndexedDb {
  constructor(database) {
    this.database = database;
    this.dispose = emitter.on("close", () => {
      console.info("Closing the database");
      this.db && this.db.close();
    });
  }
  async createObjectStore(tableNames) {
    this.db = await openDB(this.database, void 0, {
      upgrade(db, oldVersion, newVersion, transaction) {
        for (const {name: tableName, id} of [
          {name: "songs", id: "id"},
          {name: "playlists", id: "id"},
          {name: "lastUpdated", id: "name"}
        ]) {
          if (db.objectStoreNames.contains(tableName)) {
            continue;
          }
          db.createObjectStore(tableName, {keyPath: id});
        }
      },
      blocked() {
        console.info("The database is BLOCKED");
      },
      blocking() {
        console.info("The database is BLOCKING");
      },
      terminated() {
        console.info("The database has been TERMINATED");
      }
    });
  }
  async getValue(tableName, id) {
    const tx = this.getOrError().transaction(tableName, "readonly");
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    return result;
  }
  async getAllValue(tableName) {
    const tx = this.getOrError().transaction(tableName, "readonly");
    const store = tx.objectStore(tableName);
    const result = await store.getAll();
    return result;
  }
  async putValue(tableName, value) {
    const tx = this.getOrError().transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    const result = await store.put(value);
    return result;
  }
  async putBulkValue(tableName, values) {
    const tx = this.getOrError().transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    for (const value of values) {
      await store.put(value);
    }
  }
  async deleteValue(tableName, id) {
    const tx = this.getOrError().transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    if (!result) {
      console.warn(`[${tableName}] Object not found during deletion (${id})`);
      return result;
    }
    await store.delete(id);
    return id;
  }
  async deleteBulkValue(tableName, ids) {
    const tx = this.getOrError().transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    for (const id of ids) {
      const result = await store.get(id);
      if (!result) {
        console.warn(`[${tableName}] Object not found during deletion (${id})`);
        continue;
      }
      await store.delete(id);
    }
  }
  getOrError() {
    if (!this.db)
      throw Error("Please call createObjectStore first");
    return this.db;
  }
}
export const getMaxUpdatedAt = (songs) => {
  let maxUpdatedAt = 0;
  songs.forEach((song) => {
    maxUpdatedAt = Math.max(maxUpdatedAt, song.updatedAt.seconds * 1e3 + Math.round(song.updatedAt.nanoseconds / 1e6));
  });
  return maxUpdatedAt;
};
export const useCoolDB = () => {
  const {user} = useUser();
  useEffect(() => {
    const disposers = [];
    const init = async () => {
      if (!user)
        return;
      cache = {};
      const db = new IndexedDb(user.uid);
      await db.createObjectStore([]);
      const songs = clientDb(user.uid).songs();
      const playlists = clientDb(user.uid).playlists();
      const watchModel = async (model, collection) => {
        let lastUpdated = await db.getValue("lastUpdated", model);
        let items;
        if (lastUpdated === void 0) {
          console.info(`[${model}] The last updated time was undefined. Fetching entire collection...`);
          items = await withPerformanceAndAnalytics(() => collection.where("deleted", "==", false).get().then((r) => r.docs.map((doc) => doc.data())), model);
          console.info(`[${model}] Success! Got ${items.length} items from collection.`);
          await db.putBulkValue(model, items);
          console.info(`[${model}] Success! Wrote ${items.length} items to IndexedDB`);
          const maxUpdatedAt = getMaxUpdatedAt(items);
          lastUpdated = {name: model, value: maxUpdatedAt};
          await db.putValue("lastUpdated", lastUpdated);
        } else {
          items = await db.getAllValue(model).then((items2) => items2.map((item) => ({
            ...item,
            updatedAt: new firebase.firestore.Timestamp(item.updatedAt.seconds, item.updatedAt.nanoseconds),
            createdAt: item.createdAt && new firebase.firestore.Timestamp(item.createdAt.seconds, item.createdAt.nanoseconds)
          })));
          console.info(`[${model}] Loaded ${items.length} ${model} from IndexedDB`);
        }
        cache[model] = items;
        watchers.emit(model, items, [], []);
        const updateItems = async (newItems, changedItems, changes2) => {
          cache[model] = newItems;
          items = newItems;
          watchers.emit(model, newItems, changedItems, changes2);
          await db.putBulkValue(model, newItems);
          await db.deleteBulkValue(model, changes2.filter((change) => change.type === "delete").map((change) => change.item.id));
        };
        const lastUpdatedDate = new Date(lastUpdated?.value ?? 0);
        console.info(`[${model}] Looking for data updated >= ${lastUpdated?.value ?? 0} (${lastUpdatedDate.toLocaleDateString("en", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true
        })})`);
        let running = false;
        const changes = [];
        const startProcessor = async () => {
          if (running)
            return;
          if (changes.length === 0)
            return;
          running = true;
          const changesToProcess = changes.splice(0, changes.length);
          const eventName = `${model}_snapshot`;
          const trace = firebase.performance().trace(eventName);
          trace.start();
          firebase.analytics().logEvent(eventName, {
            value: changesToProcess.length
          });
          console.info(`[${model}] Got ${model} snapshot with ${changesToProcess.length} changes!`, changesToProcess);
          const copy = [...items];
          const dbChanges = [];
          const add = (change) => {
            const data = change.doc.data();
            if (data.deleted) {
              console.warn(`[${model}] Document "${change.doc.id}" not being added to the ${model} collection as it's been deleted`);
              return;
            }
            console.info(`[${model}] Adding document "${change.doc.id}" to the ${model} collection`);
            dbChanges.push({type: "add", item: data});
            copy.push(data);
          };
          const mutate = (change, index) => {
            const data = change.doc.data();
            if (data.deleted) {
              console.info(`[${model}] Deleting document "${change.doc.id}" in the ${model} collection (index ${index})`);
              dbChanges.push({type: "delete", item: copy[index]});
              copy.splice(index, 1);
              return;
            }
            console.info(`[${model}] Mutating document "${change.doc.id}" in the ${model} collection (index ${index})`);
            if (copy[index].updatedAt.toMillis() > data.updatedAt.toMillis()) {
              console.warn(`[${model}] Received a change that is out-of-date with the current state of "${data.id}". ${copy[index].updatedAt} vs. ${data.updatedAt}`);
              return;
            }
            dbChanges.push({type: "mutate", item: data});
            copy[index] = data;
          };
          const changedItems = [];
          changesToProcess.forEach((change) => {
            changedItems.push(change.doc.data());
            if (change.type === "removed") {
              return;
            }
            const index = copy.findIndex((item) => item.id === change.doc.id);
            if (index === -1)
              add(change);
            else
              mutate(change, index);
          });
          const maxUpdatedAt = getMaxUpdatedAt(changedItems);
          await updateItems(copy, changedItems, dbChanges);
          if (maxUpdatedAt < latestLastUpdated) {
            const warning = `The snapshot (${maxUpdatedAt}) was received out-of-order. The previous snapshot time was ${latestLastUpdated}.`;
            captureMessage(warning, Severity.Warning);
            console.warn(warning);
          }
          console.info(`[${model}] The previous last updated time was ${latestLastUpdated}. Setting to ${maxUpdatedAt}.`);
          await db.putValue("lastUpdated", {name: model, value: maxUpdatedAt});
          latestLastUpdated = maxUpdatedAt;
          trace.stop();
          trace.putMetric("count", changesToProcess.length);
          running = false;
          startProcessor();
        };
        let latestLastUpdated = lastUpdated.value;
        return collection.orderBy("updatedAt", "asc").where("updatedAt", ">=", lastUpdatedDate).onSnapshot({}, (snapshot) => {
          changes.push(...snapshot.docChanges().filter((change) => change.type !== "removed"));
          startProcessor();
        });
      };
      disposers.push(await watchModel("songs", songs));
      disposers.push(await watchModel("playlists", playlists));
    };
    init();
    return () => {
      disposers.forEach((dispose) => dispose());
      disposers.splice(0, disposers.length);
    };
  }, [user]);
};
const useCoolItems = function(model, onlyNew) {
  const [items, setItems, itemsRef] = useStateWithRef(cache[model]);
  useEffect(() => {
    if (cache[model] && !itemsRef.current) {
      console.info(`Initializing ${model} to cache`, cache[model]);
      setItems(cache[model]);
    }
    return watchers.on(model, (items2, _, changes) => {
      if (onlyNew && changes && changes.every((change) => change.type === "mutate")) {
        console.info(`[${model}] Skipped updating, no added documents in ${changes.length} changes.`);
        return;
      }
      console.info(`[${model}] Updating due to ${changes?.length ?? "null"} changes.`);
      setItems(items2);
    });
  }, [model, setItems, itemsRef, onlyNew]);
  return items;
};
const useSort = (items, sort) => {
  return useMemo(() => items?.sort(sort), [items]);
};
export const useChangedSongs = (cb) => {
  useEffect(() => {
    return watchers.on("songs", (_, changedDocuments) => {
      cb(changedDocuments);
    });
  }, [cb]);
};
export const useCoolSongs = () => useSort(useCoolItems("songs"), (a, b) => a.title.localeCompare(b.title));
export const useCoolPlaylists = () => useSort(useCoolItems("playlists"), (a, b) => a.name.localeCompare(b.name));
