import React, {createContext, useEffect, useState, useContext, useCallback, useRef} from "../_snowpack/pkg/react.js";
import firebase from "../_snowpack/pkg/firebase/app.js";
import {err, ok} from "../_snowpack/pkg/neverthrow.js";
import {captureAndLog} from "./utils.js";
import * as Sentry from "../_snowpack/pkg/@sentry/browser.js";
export const UserContext = createContext({user: void 0, loading: true});
let globalUser;
export const getGlobalUser = () => globalUser;
export const getDefinedUser = () => {
  if (!globalUser)
    throw Error("User is undefined");
  return globalUser;
};
export const UserProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const checkUser = useCallback((newUser) => {
    globalUser = newUser ?? void 0;
    setUser(newUser ?? void 0);
    if (loading) {
      setLoading(false);
    }
  }, [loading]);
  useEffect(() => {
    if (!user || user.emailVerified)
      return;
    const id = setInterval(() => {
      user.reload().then(() => {
        if (user.emailVerified) {
          const clone = Object.assign(Object.create(Object.getPrototypeOf(user)), user);
          setUser(clone);
        }
      });
    }, 5e3);
    return () => clearTimeout(id);
  }, [user]);
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(checkUser);
  }, [checkUser]);
  return /* @__PURE__ */ React.createElement(UserContext.Provider, {
    value: {user, loading}
  }, props.children);
};
export const useUser = () => {
  return useContext(UserContext);
};
export const useUserChange = (cb) => {
  const {user} = useUser();
  const previous = useRef();
  useEffect(() => {
    if (previous.current === user?.uid)
      return;
    previous.current = user?.uid;
    cb(user);
  }, [cb, user]);
};
export const useDefinedUser = () => {
  const {user} = useUser();
  if (!user) {
    throw Error("User is undefined! This should not happen.");
  }
  return user;
};
export const sendPasswordResetEmail = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return ok({});
  } catch (e) {
    const code = e.code;
    switch (code) {
      case "auth/invalid-email":
        return err({
          code,
          message: "I don't know how to tell you this but your email is invalid."
        });
      case "auth/user-not-found":
        return ok({});
      default:
        captureAndLog(e);
        return err({
          code: "unknown",
          message: "There was an issue resetting your password. If this persists, please contact support."
        });
    }
  }
};
export const resetPassword = async (user) => {
  if (!user.email) {
    Sentry.captureMessage("A user tried to reset their password but they don't have an email.", Sentry.Severity.Error);
    return err("An unknown error has occurred. Please contact support.");
  }
  const result = await sendPasswordResetEmail(user.email);
  if (result.isErr()) {
    return err(result.error.message);
  }
  return ok("Liftoff! We've sent you an email with more instructions.");
};
export const deleteAccount = async (user, confirmPassword) => {
  try {
    await user.delete();
    return ok(void 0);
  } catch (e) {
    const code = e.code;
    switch (code) {
      case "auth/requires-recent-login":
        const confirmed = await confirmPassword();
        if (confirmed) {
          return await deleteAccount(user, confirmPassword);
        } else {
          return ok(void 0);
        }
      default:
        return err("Unable to delete your account. Please contact support \u{1F641}");
    }
  }
};
export const changeEmail = async (user, newEmail, confirmPassword) => {
  if (newEmail === "") {
    return err("Did you mean to submit an empty form?");
  }
  if (user.email === newEmail) {
    return ok("We're happy to inform you that that is already your email.");
  }
  try {
    await user.verifyBeforeUpdateEmail(newEmail);
    return ok("Success! Check your email for a verification link.");
  } catch (e) {
    const code = e.code;
    switch (code) {
      case "auth/internal-error":
        return err("Please check your email and try again.");
      case "auth/requires-recent-login":
        const confirmed = await confirmPassword();
        if (confirmed) {
          return await changeEmail(user, newEmail, confirmPassword);
        } else {
          return ok(void 0);
        }
      default:
        captureAndLog(e);
        return err("An unknown error occurred while resetting your email.");
    }
  }
};
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    return ok(await firebase.auth().signInWithEmailAndPassword(email, password));
  } catch (e) {
    const code = e.code;
    switch (code) {
      case "auth/invalid-email":
        return err({
          code: "auth/invalid-email",
          message: "Please provide a valid email address."
        });
      case "auth/user-not-found":
      case "auth/wrong-password":
        return err({
          code: "auth/wrong-password",
          message: "Invalid credentials. Please try again!"
        });
      case "auth/network-request-failed":
        return err({
          code: "auth/network-request-failed",
          message: "Network error. Please try again."
        });
      default:
        captureAndLog(e, {code});
        return err({
          code: "unknown",
          message: "Something went wrong. Please try again!"
        });
    }
  }
};
