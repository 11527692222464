export const SNOWPACK_PUBLIC_PACKAGE_VERSION = "0.19.0";
export const SNOWPACK_PUBLIC_BACKEND_URL = "https://us-central1-relar-staging.cloudfunctions.net";
export const SNOWPACK_PUBLIC_API_KEY = "AIzaSyA0Eg_alYNTMquuAU8YS9R1HAhBtZsTPlQ";
export const SNOWPACK_PUBLIC_AUTH_DOMAIN = "relar-staging.firebaseapp.com";
export const SNOWPACK_PUBLIC_PROJECT_ID = "relar-staging";
export const SNOWPACK_PUBLIC_STORAGE_BUCKET = "relar-staging.appspot.com";
export const SNOWPACK_PUBLIC_MESSAGING_SENDER_ID = "768688101080";
export const SNOWPACK_PUBLIC_APP_ID = "1:768688101080:web:8fa73945d0be5bf1f529ad";
export const SNOWPACK_PUBLIC_MEASUREMENT_ID = "G-JQJLGSLXMJ";
export const SNOWPACK_PUBLIC_SENTRY_DSN = "https://ae6c432b2c074f17b223ddd11df69461@o400394.ingest.sentry.io/5258806";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;