import React, {useState} from "../../_snowpack/pkg/react.js";
import {useUser} from "../auth.js";
import {BsExclamationTriangle} from "../../_snowpack/pkg/react-icons/bs.js";
import {Input} from "./Input.js";
import {BlockAlert} from "./BlockAlert.js";
import {OkCancelModal} from "./OkCancelModal.js";
export const ActionConfirmationModal = ({
  title,
  subtitle,
  confirmText,
  onConfirm,
  onCancel,
  confirmEmail
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const {user} = useUser();
  const checkEmail = () => {
    if (!confirmEmail || email === user?.email) {
      return onConfirm();
    } else {
      setError("You email does not match. Please try again!");
    }
  };
  return /* @__PURE__ */ React.createElement(OkCancelModal, {
    onCancel,
    okText: confirmText,
    onOk: checkEmail,
    titleText: title,
    okTheme: !confirmEmail || email === user?.email ? "red" : "disabled",
    wrapperClassName: "flex dark:text-gray-200"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "px-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "bg-red-200 rounded-full p-2"
  }, /* @__PURE__ */ React.createElement(BsExclamationTriangle, {
    className: "text-red-600 pb-1 w-6 h-6"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col space-y-2"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", {
    className: "font-bold"
  }, title), /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-gray-500 dark:text-gray-400"
  }, subtitle)), confirmEmail && /* @__PURE__ */ React.createElement(Input, {
    placeholder: user?.email ?? void 0,
    value: email,
    onChange: setEmail,
    onEnter: checkEmail,
    label: "Confirm Email"
  }), error && /* @__PURE__ */ React.createElement(BlockAlert, {
    type: "error"
  }, error)));
};
